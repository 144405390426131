
:root {
  --primary-main: #46C1C5;
  --primary-dark: #03242f;
  --white: #ffffff;

  --info-lightest: #ECFDFF;
  --info-light: #CFF9FE;

  --neutral-50: #F8F9FA;
  --neutral-100: #F3F4F6;
  --neutral-200: #E5E7EB;
  --neutral-300: #D2D6DB;
  --neutral-400: #9DA4AE;
  --neutral-500: #6C737F;
  --neutral-600: #4D5761;
  --neutral-700: #2F3746;
  --neutral-800: #1C2536;
  --neutral-900: #111927;

}

.pulse {
  position: relative;
}
.pulse .pulse-ring {
  top: -10px;
  left: -11.5px;
  display: block;
  border-radius: 45px;
  height: 45px;
  width: 45px;
  position: absolute;
  -webkit-animation: animation-pulse 3.5s ease-out;
  animation: animation-pulse 3.5s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
  border-width: 3px;
  border-style: solid;
  border-color: #f04438;
}

@-webkit-keyframes animation-pulse {
  0% {
    -webkit-transform: scale(0.5, 0.5);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(0.5, 0.5);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes animation-pulse {
  0% {
    -webkit-transform: scale(0.5, 0.5);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(0.5, 0.5);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    max-height: 0px;
    max-width: 0px;
    padding-left: 0px
  }
  100% {
    opacity: 1;
    max-height: 600px;
    max-width: 600px;
    padding-left: 120px

  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
    max-height: 600px;
    max-width: 600px;
    padding-left: 120px

  }
  100% {
    opacity: 0;
    max-height: 0px;
    max-width: 0px;
    padding-left: 0px

  }
}


.tour-popover button {
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-shadow: none;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1.57;
  padding: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.tour-popover .driver-popover-close-btn {
  font-size: 1.5rem;
  color: var(--neutral-900);
}


.tour-popover .driver-popover-prev-btn {
  background-color: var(--white);
  color: var(--primary-main);
  border-color: var(--primary-main);

  width: 70px;
  height: 25px;
}

.tour-popover .driver-popover-prev-btn:hover {
background-color: var(--info-lightest);
}


.tour-popover .driver-popover-next-btn {
  background-color: var(--primary-main);
  color: var(--white);
  border: none;
  width: 70px;
  height: 25px;

}

.tour-popover .driver-popover-next-btn:hover {
  background-color: var(--primary-dark);

}


.tour-popover .driver-popover-title {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  /* typography subtitle1 */
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.57
}

.tour-popover .driver-popover-description {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  font-size: 0.85rem;
  line-height: 1.57
}

.tour-popover .driver-popover-progress-text {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  /* typography caption */
  font-size: 0.75rem;
  line-height: 1.57
}

.rccs {
  margin: 0 !important;
}

.rpv-thumbnail__list, .rpv-thumbnail__list--vertical {
  overflow: hidden !important;
}
